import {ElMessageBox} from 'element-plus'
import type {Router} from 'vue-router'

import {getShopConfig} from '@/apis/system-api'
import {useShopStoreHook} from '@/stores/modules/shop'

const shopStore = useShopStoreHook()

// 获取平台设置的店铺过期提醒时间
export async function getShopRenewalReminderDay(): Promise<number> {
    const configs = await getShopConfig()
    console.log('店铺系统配置 - /cashier/api/shop/config: ', configs)
    const item = configs.find((config: Obj) => config.paramKey === 'shop_renewal_reminder_day')
    return (item?.paramValue as number) || 20
}

export const checkShopRenewalInfo = async (router: Router) => {
    const shopRenewalReminderDay = await getShopRenewalReminderDay()
    console.log('平台设置的店铺过期提醒时间: ', shopRenewalReminderDay)
    const remainDays = await shopStore.getShopRemainDays()
    // 小于 店铺过期提醒时间 事后提醒
    if (remainDays <= shopRenewalReminderDay) {
        try {
            await ElMessageBox.confirm(
                `您的店铺${remainDays > 0 ? `使用时间还有${remainDays.toFixed(0)}天到期` : '已到期'}，请尽快续费`,
                '续费提醒',
                {
                    confirmButtonText: '立即续费',
                    cancelButtonText: '关闭',
                    type: 'warning',
                    showClose: remainDays > 0,
                    showCancelButton: remainDays > 0,
                    closeOnClickModal: false,
                }
            )
            router.push('/renewal')
        } catch (err) {
            console.log(err)
        }
    }
}
