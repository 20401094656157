import { store } from '@/stores'
import { defineStore } from 'pinia'
import { storageLocal } from '@pureadmin/utils'
import { getDefaultLayoutConfig } from '@/config/theme'

const { VITE_APP_NAME } = import.meta.env

const KEY_OF_LAYOUT = `${VITE_APP_NAME}-layout`

export const useThemeStore = defineStore({
  id: 'theme',
  state: () => ({
    themeColor:
      storageLocal().getItem<StorageConfigs>(KEY_OF_LAYOUT)?.themeColor ??
      getDefaultLayoutConfig().color,
    theme:
      storageLocal().getItem<StorageConfigs>(KEY_OF_LAYOUT)?.theme ?? getDefaultLayoutConfig().name
  }),
  getters: {
    getThemeColor(state) {
      return state.themeColor
    }
  },
  actions: {
    setThemeColor({ name, color }: ThemeColor): void {
      this.theme = name
      this.themeColor = color
      const layout = this.getStorageLayout() || {}
      storageLocal().setItem(
        KEY_OF_LAYOUT,
        Object.assign(layout, { theme: name, themeColor: color })
      )
    },
    getStorageLayout() {
      return storageLocal().getItem<StorageConfigs>(KEY_OF_LAYOUT)
    }
  }
})

export function useThemeStoreHook() {
  return useThemeStore(store)
}
