import { createApp } from 'vue'
import axios, { type AxiosResponse } from 'axios'
import mobile from 'is-mobile'

import App from './App.vue'
import { router, __router__ } from './router'
import { setupStore } from '@/stores'
import { useTheme } from '@/hooks/useTheme'
import { getToken, setToken } from '@/hooks/useUser'

import '@/styles/index.scss'
import '@/styles/scrollbar.scss'

import XEUtils from 'xe-utils'
import {
  // 全局对象
  VXETable,

  // 表格功能
  // Filter,
  // Edit,
  // Menu,
  // Export,
  // Keyboard,
  // Validator,

  // 可选组件
  Icon,
  Column,
  Colgroup,
  // Grid,
  // Tooltip,
  // Toolbar,
  // Pager,
  // Form,
  // FormItem,
  // FormGather,
  // Checkbox,
  // CheckboxGroup,
  // Radio,
  // RadioGroup,
  // RadioButton,
  // Switch,
  // Input,
  // Select,
  // Optgroup,
  // Option,
  // Textarea,
  // Button,
  // Modal,
  // List,
  // Pulldown,

  // 表格
  Table
} from 'vxe-table'
import zhCN from 'vxe-table/es/locale/lang/zh-CN'
import 'vxe-table/styles/cssvar.scss'
import { useZoom } from "@/hooks/useZoom";

const { initZoom } = useZoom();

initZoom()

// 按需加载的方式默认是不带国际化的，自定义国际化需要自行解析占位符 '{0}'，例如：
VXETable.setup({
  i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args)
})

function useTable(app: App) {
  // 表格功能
  // app.use(Filter)
  // .use(Edit)
  // .use(Menu)
  // .use(Export)
  // .use(Keyboard)
  // .use(Validator)

  // 可选组件
  app
    .use(Icon)
    .use(Column)
    .use(Colgroup)
    // .use(Grid)
    // .use(Tooltip)
    // .use(Toolbar)
    // .use(Pager)
    // .use(Form)
    // .use(FormItem)
    // .use(FormGather)
    // .use(Checkbox)
    // .use(CheckboxGroup)
    // .use(Radio)
    // .use(RadioGroup)
    // .use(RadioButton)
    // .use(Switch)
    // .use(Input)
    // .use(Select)
    // .use(Optgroup)
    // .use(Option)
    // .use(Textarea)
    // .use(Button)
    // .use(Modal)
    // .use(List)
    // .use(Pulldown)

    // 安装表格
    .use(Table)
}

// if (mobile()) {
//   location.replace(location.origin + '/' + import.meta.env.VITE_H5_BASE_URL)
// }

// axios.defaults.baseURL = import.meta.env.VITE_BASE_API_URL
axios.defaults.baseURL = '/api'
axios.interceptors.request.use((config) => {
  if (config.headers) {
    const token = getToken()
    if (token !== null) {
      config.headers['Authorization-Token'] = token
    }
  }

  if (!config.paramsSerializer) {
    config.paramsSerializer = {}
  }
  config.paramsSerializer = { ...config.paramsSerializer, indexes: null }

  config.withCredentials = true

  return config
})

const handleResponse = (response: AxiosResponse) => {
  const token = response.headers['authorization-token']
  // 忽略发送短信接口返回的 token remove
  if (
    // !response.config?.url?.includes('sms-send') &&
    typeof token === 'string' &&
    token.length > 0
  ) {
    setToken(token)
  }
  if (response.status === 401) {
    setToken('')
    if (window.location.pathname.indexOf('login') >= 0) {
      return Promise.reject(response)
    }
    const query: any = {
      redirectUrl: window.location
    }
    router.replace({
      name: 'login',
      query: query
    })
  }

  if (response.status === 200 || response.status === 400) {
    if ('USER:NON-PASSWORD' === response.data?.code) {
      const query: any = {
        redirectUrl: window.location
      }
      router.replace({
        name: 'set-password',
        query: query
      })
    }
    if ('LOGIN:NON-SHOP' === response.data?.code) {
      router.replace({ name: 'shop-select' })
    }
  }
  return null
}

axios.interceptors.response.use(
  (response) => {
    const result = handleResponse(response)
    if (result !== null) {
      return result
    }
    return Promise.resolve(response)
  },
  (error) => {
    if (error.response) {
      const result = handleResponse(error.response)
      if (result !== null) {
        return result
      }
    }
    return Promise.reject(error)
  }
)

const app = createApp(App).use(useTable)

setupStore(app)

__router__.then(() => {
  app.use(router)
  app.mount('#app')
})

// 初始化主题
useTheme().initTheme()
