import {defineStore} from 'pinia'
import dayjs from "dayjs";

import {getShopRenewalInfo} from '@/apis/shop-api'
import {getServerTime} from '@/apis/system-api'

import {store} from '@/stores'

// 获取店铺剩余使用天数
const getShopRemainDays = async () => {
    const {dueDate} = await getShopRenewalInfo()
    console.log('# dueDate: ', dueDate)
    const serverTime = await getServerTime()
    const day = (dayjs(dueDate).endOf('day').toDate().getTime() - new Date(serverTime).getTime()) / (24 * 60 * 60 * 1000)
    return day > 0 ? day + 1 : day
}

export const useShopStore = defineStore({
    id: 'shop',
    state: () => ({
        remainDays: Number.POSITIVE_INFINITY
    }),
    getters: {
        isDue(state) {
            return state.remainDays <= 0
        }
    },
    actions: {
        async getShopRemainDays() {
            if (this.remainDays !== Number.POSITIVE_INFINITY) {
                return this.remainDays
            }
            const remainDays = await getShopRemainDays()
            this.remainDays = remainDays
            return remainDays
        }
    }
})

export function useShopStoreHook() {
    return useShopStore(store)
}
