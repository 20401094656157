import { get, post } from "./api-request";

type Commission = {
    outageCheckout?: number
    outageClean?: number
}

export async function getOutage(): Promise<Commission> {
    return (await get("/cashier/api/shop-setting/outage/get")).data;
}

export async function setOutage(data: Commission): Promise<any> {
    return (await post("/cashier/api/shop-setting/outage/set", data)).data;
}

export async function getAddressData(): Promise<AddressOption[]> {
    return (await get("/cashier/api/sysconfig/address-all-list")).data;
}

// 获取店铺系统配置
export async function getShopConfig(): Promise<any> {
    return (await get("/cashier/api/shop/config")).data;
}

export async function getServerTime(): Promise<DateTime> {
    const res = await get("/cashier/api/system/time");
    console.log("# 服务器时间: ", res.data);
    return new Date(res.data);
}
