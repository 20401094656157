import { createRouter, createWebHistory, type Router } from 'vue-router'
import { getRoutes } from './routing'

import { useShopStoreHook } from '@/stores/modules/shop'
import { checkShopRenewalInfo } from '@/utils/shop'
import { getToken } from '@/hooks/useUser'

const shopStore = useShopStoreHook()

let router: Router

/**
 * 判断 path 是否在过期之后仍能访问
 * 店铺续费 和 店铺管理 以及 登录的
 * @param path
 * @returns
 */
function canAccessAfterDue(path: string) {
  return ['/renewal', '/shop/management'].includes(path) || path.includes('login')
}

const __router__ = getRoutes({
  views: import.meta.glob('../views/**/**.vue'),
  viewConfig: import.meta.glob('../views/**/**.ts')
}).then((routes) => {
  const routerTmp = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
  })

  routerTmp.beforeEach(async (to, from) => {
    // 登录页/授权页 不进行 店铺有效期的检查
    if (to.path.includes('login') || to.path.includes('oauth2')) {
      return true
    }
    // 已登录 到期了只能切换店铺或退出
    if (getToken()) {
      await shopStore.getShopRemainDays()
      console.log('to route: ', to)
      console.log('from route: ', from)
      console.log('店铺剩余到期时间: ', shopStore.remainDays)
      // 店铺过期了 且 访问的页面不被允许, 则跳转到店铺续费页
      if (shopStore.isDue && !canAccessAfterDue(to.path as string)) {
        console.log('# 店铺过期了')
        return { name: 'renewal' }
      }
      // 未过期的 且 从登录页面 过来的, 检查有效期
      if (from.path.includes('login') || from.path === '/') {
        checkShopRenewalInfo(router)
      }
      return true
    }
    return { name: 'login' }
  })

  routerTmp.onError((...args) => {
    console.log('onError: ', args)
  })

  router = routerTmp
  return routerTmp
})

export { router, __router__ }
