import { ref } from "vue";

const KEY = "CASHIER_MERCHART_ZOOM";

export function useZoom() {
    const zoom = ref<string>(localStorage.getItem(KEY) || "1");

    function changeZoom(val: string) {
        zoom.value = val;
        document.body.style.zoom = val;
        localStorage.setItem(KEY, String(val));
    }

    function initZoom() {
        document.body.style.zoom = zoom.value;
    }

    return {
        zoom,
        changeZoom,
        initZoom
    };
}
