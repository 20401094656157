import {get, post} from "@/apis/api-request";
import {ref} from "vue";

/**
 * 获取自定义支付方式
 */
export async function getPayChannelCustom(): Promise<PayChannelCustom[]> {
    return await (
        await get('/cashier/api/pay/channel/custom/list/available')
    ).data.data
}

export async function editPayChannelCustom(payChannelCustom: PayChannelCustom): Promise<any> {
    return (await post('/cashier/api/pay/channel/custom/update', payChannelCustom)).data
}


const ShopPayChannelCustomKey = "shop-pay-channel-custom";

export const loadShopPayChannelCustom = () => {
    try {
        //console.log('loadShopPayChannelCustom');
        //console.log(JSON.parse(sessionStorage.getItem(ShopPayChannelCustomKey as string)));
        return JSON.parse(sessionStorage.getItem(ShopPayChannelCustomKey as string));
    } catch (err) {
        console.error("# 从缓存中自定义支付方式失败：", err);
        return initPayChannels2Session();
    }
};

const payChannelDic = ref<[]>([]);
export const initPayChannels2Session = async () => {
    clearPayChannels2Session();
    const data = await getPayChannelCustom();
    //console.log("-----------------------patyChannel:" + data);
    for (let i = 0; i < data.length; i++) {
        const key = data[i].code;
        const name = data[i].name;
        payChannelDic[key] = name;
    }
    sessionStorage.setItem(ShopPayChannelCustomKey, JSON.stringify(payChannelDic));
    //console.log("-----------------------updatePayChannel2Session:" + JSON.stringify(payChannelDic))
    return payChannelDic;
};

export const clearPayChannels2Session = () => {
    sessionStorage.removeItem(ShopPayChannelCustomKey);
};

//key:[枚举值，支付名称，支付名称simple]
const payChannelDictionary = {
    'cash': [1, '现金支付', '现金'],//现金支付
    'vipBalance': [2, '会员余额', '余额'],//会员余额
    'vipGift': [4, '赠送余额', '赠送余额'],//赠送余额
    'vipIntegral': [8, '会员积分', '积分'],//会员积分
    'mtpay': [1024, '美团', '美团'],//美团
    'dypay': [2048, '抖音', '抖音'],//抖音支付
    'onlineWechat': [64, '微信在线', '线上(微)'],//微信在线支付
    'onlineAlipay': [128, '支付宝在线', '线上(支)'],//支付宝在线支付
    'onlinePay': [512, '在线支付', '线上'],//在线支付
    'onliePay': [512, '在线支付', '线上'],//在线支付
    'alipay': [32, '支付宝线下', '线下(支)'],//支付宝线下
    'wechat': [16, '微信线下', '线下(微)'],//微信线下
    'offlineWechat': [16, '微信线下', '线下(微)'],//微信线下
    'offlineAlipay': [32, '支付宝线下', '线下(支)']//微信线下
}

export const payNameHandler = (payModelKey: string, simple?: boolean) => {
    const innerDic = loadShopPayChannelCustom();
    //console.log('payModelKey:'+payModelKey);
    let finalName = '';
    const payEnum = payChannelDictionary[payModelKey];
    if (null != payEnum && undefined != payEnum) {
        const paystr = (undefined != simple && simple) ? payEnum[2] : payEnum[1];
        //console.log('paystr:'+paystr);
        const fromDBChannelName = innerDic[payModelKey];
        //console.log('fromDBChannelName:'+fromDBChannelName);
        finalName = undefined == fromDBChannelName ? paystr : fromDBChannelName;
        //console.log('finalName:'+finalName);
    }
    return finalName;
};


const payChannelDictionaryReverse = {
    1: ['cash', '现金支付'],//现金支付
    2: ['vipBalance', '会员余额'],//会员余额
    4: ['vipGift', '赠送余额'],//赠送余额
    8: ['vipIntegral', '会员积分'],//会员积分
    1024: ['mtpay', '美团'],//美团
    2048: ['dypay', '抖音'],//抖音支付
    64: ['onlineWechat', '微信在线'],//微信在线支付
    128: ['onlineAlipay', '支付宝在线'],//支付宝在线支付
    512: ['onlinePay', '在线支付'],//在线支付
    32: ['alipay', '支付宝线下'],//支付宝线下
    16: ['wechat', '微信线下']//微信线下
}

export const payNameHandlerReverse = (payModelNumber: number) => {
    const innerDic = loadShopPayChannelCustom();
    let finalName = '';
    const payEnum = payChannelDictionaryReverse[payModelNumber];
    if (null != payEnum && undefined != payEnum) {
        const paystr = payEnum[0];
        const fromDBChannelName = innerDic[paystr];
        finalName = undefined == fromDBChannelName ? payEnum[1] : fromDBChannelName;
    }
    return finalName;
};