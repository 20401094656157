const THEME_COLORS: ThemeColors = {
  // 浅褐
  LightBrown: {
    name: 'LightBrown',
    nameCn: '浅褐',
    color: '#966C54',
    fromColor: '#96674C',
    toColor: '#957B6C'
  },
  // 红棕
  RedBrown: {
    name: 'RedBrown',
    nameCn: '红棕',
    color: '#CC6363',
    fromColor: '#985E5B',
    toColor: '#7D4642'
  },
  // 墨蓝
  DarkBlue: {
    name: 'DarkBlue',
    nameCn: '墨蓝',
    color: '#3E80BC',
    fromColor: '#3E80BC',
    toColor: '#285F87'
  },
  // 墨绿
  DarkGreen: {
    name: 'DarkGreen',
    nameCn: '墨绿',
    color: '#47938A',
    fromColor: '#5F9C99',
    toColor: '#26615E'
  }
}

const getDefaultLayoutConfig = () => {
  return THEME_COLORS.LightBrown
}

export { THEME_COLORS, getDefaultLayoutConfig }
