import {paramCase} from 'change-case'
import type {RouteRecordRaw} from 'vue-router'
import {hasAuthorityOfPage} from "@/utils/auth"

const defaultViewName = 'index'
const doubleCrossBar = new RegExp('--', 'g')

function isVueFile(name: string): boolean {
  return /\.vue/.test(name)
}


function getDefault<T>(defaultValue: T | (() => T), value?: T): T {
  if (value) {
    return value
  }
  if (typeof defaultValue === 'function') {
    return (defaultValue as any)()
  }
  return defaultValue
}

function loadViews(
    views?: Record<string, () => Promise<unknown>>
): Record<string, () => Promise<unknown>> {
  return getDefault(() => import.meta.glob('../views/**/**.vue'), views)
}

function loadViewConfig(
    configs?: Record<string, () => Promise<unknown>>
): Record<any, Promise<any>> {
  const result: Record<any, Promise<any>> = {}
  if (configs) {
    for (const key in configs) {
      const index = key.lastIndexOf('.')
      if (index >= 0) {
        result[key.substring(0, index)] = configs[key]()
      } else {
        result[key] = configs[key]()
      }
    }
  }
  return result
}

function concatPath(rootPath: string, path: string, pageName: string): string {
  let result = rootPath
  if (path !== pageName) {
    if (path !== defaultViewName) {
      if (!result.endsWith('/')) {
        result += '/'
      }
      result += path
    }
    if (result.length < 1) {
      result = '/'
    }
  }
  return result
}

function formatName(name: string): string {
  name = name.replace(/\//g, '-')
  while (name.startsWith('-')) {
    name = name.substring(1)
  }
  while (name.endsWith('-')) {
    name = name.substring(0, name.length - 1)
  }
  return name.replace(doubleCrossBar, '-')
}

async function loadRoute(
    value: string,
    path: string,
    views: Record<string, () => Promise<unknown>>,
    viewConfigs: Record<string, Promise<any>>
): Promise<RouteRecordRaw> {
  let route: RouteRecordRaw = null as any
  if (typeof value === 'string') {
    const name = formatName(path)
    route = (await viewConfigs[value.replace('.vue', '')])?.default as any
    if (typeof route !== 'object' || route === null) {
      route = {} as any
    }
    if (!route) {
      route = {} as any
    }
    if (path.length > 1 && path.endsWith('/')) {
      path = path.substring(0, path.length - 1)
    }
    if (!route.path) {
      route.path = path
    }
    if (!route.name && name) {
      route.name = name
      // let hasAuth = true
      // console.log('##### 组装 routes')
      // try {
      //   hasAuth = await hasAuthorityOfPage(route.name)
      // } finally {
      //     if (!hasAuth) {
      //         route = null as any
      //     }
      // }
    }
    if (route && !route.component) {
      route.component = views[value]
    }
  }

  return route
}

async function toRoutes(
    views: Record<string, () => Promise<unknown>>,
    viewConfigs: Record<string, Promise<any>>,
    viewPaths: Record<string, string>,
    pageName: string,
    rootPath: string = '/'
): Promise<Array<RouteRecordRaw>> {
  let currentPath = rootPath
  const rootRoute: RouteRecordRaw = await loadRoute(
      viewPaths[defaultViewName],
      concatPath(rootPath, '', pageName),
      views,
      viewConfigs
  )
  let result: RouteRecordRaw[] = []
  if (rootRoute) {
    result.push(rootRoute)
  }
  for (const key in viewPaths) {
    if (key === 'index') {
      continue
    }
    currentPath = concatPath(currentPath, key, pageName)
    const value = viewPaths[key]
    if (typeof value === 'string') {
      const route = await loadRoute(value, currentPath, views, viewConfigs)
      result.push(route)
      currentPath = rootPath
    }
    if (typeof value === 'object') {
      if (key === pageName) {
        // 子页面
        if (!Array.isArray(rootRoute.children)) {
          rootRoute.children = []
        }
        const routes = await toRoutes(views, viewConfigs, value, pageName, currentPath)
        rootRoute.children = rootRoute.children.concat(routes)
        if (routes?.length && rootRoute.redirect
            && !routes.some(route=> route.name === rootRoute.redirect.name)) {
          rootRoute.redirect.name = routes[0].name
        }
      } else {
        const routes = await toRoutes(views, viewConfigs, value, pageName, currentPath)
        result = result.concat(routes.filter(route => !route.children || route.children?.length))
        currentPath = rootPath
      }
    }
  }
  return result
}

export function getRoutes(options?: {
  views?: Record<string, () => Promise<unknown>>
  pageName?: string
  root?: string
  viewConfig?: Record<string, () => Promise<unknown>>
}) {
  const views = loadViews(options?.views)
  const viewConfigs = loadViewConfig(options?.viewConfig)
  const rootReg = new RegExp(getDefault('../views/', options?.root))

  const pathViews = Object.keys(views).reduce((all, cur) => {
    const paths = cur.replace(rootReg, '').split('/')
    let tmp: any = all
    while (paths.length) {
      const name = paths.shift() || ''
      if (isVueFile(name)) {
        const file = name.replace('.vue', '')
        tmp[paramCase(file)] = cur
      } else {
        if (!tmp[name]) {
          tmp[name] = {}
        }
        if (typeof tmp[name] === 'string') {
          tmp[name] = {index: tmp[name]}
        }
        tmp = tmp[name]
      }
    }
    return all
  }, {})

  return toRoutes(views, viewConfigs, pathViews, getDefault('pages', options?.pageName), '/')
}
