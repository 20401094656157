import {computed, ref} from "vue";
import {isObject} from "@/utils";
import {useLoginContext} from "@/stores/login-context";
import {get} from "@/apis/api-request";
import {getPayChannelCustom} from "@/apis/payChannel"


const AuthorizationTokenKey = "authorization-token";
const AccountKey = "account";

export const getToken = () => {
    return sessionStorage.getItem(AuthorizationTokenKey) || null;
};
export const setToken = (token: string) => {
    return sessionStorage.setItem(AuthorizationTokenKey, token);
};

const _saveAccounts = (accounts: Account[]) => {
    console.log("# saveAccount: ", accounts);
    return localStorage.setItem(AccountKey, window.btoa(JSON.stringify(accounts)));
};

const _getAccounts = (): Account[] => {
    let accounts: Account[] = [];
    try {
        accounts = JSON.parse(window.atob(localStorage.getItem(AccountKey) as string));
    } catch (err) {
        console.error("# 从缓存中读取账号密码失败：", err);
    }
    return accounts || [];
};

export const getAccounts = (): Account[] => {
    return _getAccounts();
};

export const getAccount = (username: Account["username"]) => {
    const accounts: Account[] = getAccounts();
    const target = accounts.find((account: Account) => account.username === username);
    if (!target || (target?.dueDate < (new Date()).getTime())) return;
    return target;
};

export const removeAccount = (username: Account["username"]) => {
    const accounts: Account[] = getAccounts();
    const index = accounts.findIndex((account: Account) => account.username === username);
    if (index >= 0) {
        accounts.splice(index, 1);
    }
    _saveAccounts(accounts);
};

export const saveAccount = (account: Pick<Account, "username" | "password">) => {
    if (!isObject(account)) {
        console.error("# 保存的账号有误：", account);
        return;
    }

    removeAccount(account.username);

    const accounts: Account[] = getAccounts();
    accounts.unshift({
        username: account.username,
        password: account.password,
        dueDate: (new Date()).getTime() + (1000 * 60 * 60 * 24 * 7)
    });

    _saveAccounts(accounts);
};

export function useUser() {
    const token = computed(async () => {
        return getToken();
    });

    return {
        token
    };
}


export function useUserRole() {
    const loginContext = useLoginContext();
    const isAdmin = ref<boolean>(false);

    const getRole = async () => {
        const user = (await get("/cashier/api/login/user")).data;
        const shop = (await get("/cashier/api/login/shop")).data;
        isAdmin.value = user.userId === shop.userId;
    };

    return {
        isAdmin,
        getRole
    };
}

