import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import type { Router } from 'vue-router'
import { get } from '@/apis/api-request'

export const useLoginContext = defineStore('login-context', () => {
  const user = ref<{
    userId: string
    username: string
    nickname?: string
    userPhone?: string
    weixinNickname?: string
  }>()

  const shop = ref<{
    shopId: string
    shopName: string
    contactPerson?: string
    contactNumber?: string
    province?: string
    city?: string
    region?: string
    address?: string
    userId: string
    createTime: Date | number
  }>()
  const authorities = ref<boolean | Array<string>>()

  async function loadContext(router?: Router) {
    const authoritiesAsync = get('/cashier/api/login/load/authority').then(
      (response) => (authorities.value = response.data)
    )
    const loadUserAsync = get('/cashier/api/login/user').then(
      (response) => (user.value = response.data)
    )
    const loadShopAsync = get('/cashier/api/login/shop').then(
      (response) => (shop.value = response.data)
    )

    await Promise.allSettled([authoritiesAsync, loadUserAsync, loadShopAsync])
    if (typeof shop.value !== 'object' || shop.value === null) {
      if (authorities.value) {
        router?.replace({ name: 'shop-select' })
      }
    }
  }

  async function hasAuthority(authority: string): Promise<boolean> {
    return new Promise((resolve) => {
      const exec = () => {
        if (typeof authorities.value === 'undefined') {
          setTimeout(exec, 10)
          return
        }
        if (typeof authorities.value === 'boolean') {
          resolve(authorities.value)
        }
        if (Array.isArray(authorities.value)) {
          resolve(authorities.value.some((item) => item === authority))
        } else {
          resolve(false)
        }
      }
      exec()
    })
  }

  function authorize(authority: string) {
    return computed(() => {
      if (typeof authorities.value === 'boolean') {
        return authorities.value
      }
      if (Array.isArray(authorities.value)) {
        return authorities.value.some((item) => authority === item)
      }

      return false
    }).value
  }

  return { user, shop, authorities, loadContext, hasAuthority, authorize }
})
