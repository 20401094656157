import { ref } from 'vue'
import { useThemeStoreHook } from '@/stores/modules/theme'
import { darken, lighten } from '@pureadmin/theme/dist/browser-utils'
import { THEME_COLORS } from '@/config/theme'
import { getDefaultLayoutConfig } from '@/config/theme'

export function useTheme() {
  const themeName = ref<string>('')

  const themeColors = ref<ThemeColors>(THEME_COLORS)

  const dataTheme = ref<boolean>(false)
  const body = document.documentElement as HTMLElement

  /** 设置导航主题色 */
  function setLayoutThemeColor(theme = getDefaultLayoutConfig().name) {
    const themeColor = themeColors.value[theme]
    if (themeColor) {
      setThemeColor(themeColor)
      themeName.value = theme
    }
  }

  function _setPropertyPrimary(prop: string, color: string) {
    document.documentElement.style.setProperty(prop, color)
  }

  function setPropertyPrimary(mode: string, i: number, color: string) {
    _setPropertyPrimary(
      `--el-color-primary-${mode}-${i}`,
      dataTheme.value ? darken(color, i / 10) : lighten(color, i / 10)
    )
  }

  /** 设置 `element-plus` 主题色 */
  const setThemeColor = (themeColor: ThemeColor) => {
    useThemeStoreHook().setThemeColor(themeColor)
    const { color } = themeColor
    document.documentElement.style.setProperty('--el-color-primary', color)
    for (let i = 1; i <= 2; i++) {
      setPropertyPrimary('dark', i, color)
    }
    for (let i = 1; i <= 9; i++) {
      setPropertyPrimary('light', i, color)
    }
    _setPropertyPrimary('--theme-from-color', themeColor.fromColor)
    _setPropertyPrimary('--theme-to-color', themeColor.toColor)
  }

  const initTheme = () => {
    const layout = getStorageTheme()
    setLayoutThemeColor(layout?.theme)
  }

  const getStorageTheme = (): StorageConfigs => {
    return (
      useThemeStoreHook().getStorageLayout() ?? {
        theme: getDefaultLayoutConfig().name
      }
    )
  }

  return {
    themeName,
    body,
    dataTheme,
    themeColors,
    initTheme,
    setThemeColor,
    setLayoutThemeColor,
    getStorageTheme
  }
}
